<template>
  <section>
    <v-container class="white" fluid>
      <v-container>
        <v-row align="center">
          <v-col cols="12">
            <v-card class="elevation-0" color="white">
              <v-card-title
                class="display-1 blue-grey--text text--darken-2 font-weight-bold"
                style="word-break: keep-all;"
              >
                Availability
              </v-card-title>
              <v-card-text class="headline blue-grey--text text--darken-1">
                Before sending an inquiry, please use the calendar below to
                check for availability on your desired date.
                <span class="text-bold">
                  Please note that there may already be a booking inquiry for
                  the date you've selected, so availability on the calendar
                  doesn't guarantee we'll be able to accommodate your group."
                </span>
              </v-card-text>
              <v-card-text>
                <iframe
                  src="https://calendar.google.com/calendar/embed?height=600&wkst=1&bgcolor=%23ffffff&ctz=Europe%2FDublin&showPrint=0&showTabs=0&showCalendars=0&showTz=0&hl=en_GB&title=Portmarnock%20Den&src=Ym9va2luZ3NAcG9ydG1hcm5vY2tkZW4uY29t&src=ZW4uaXJpc2gjaG9saWRheUBncm91cC52LmNhbGVuZGFyLmdvb2dsZS5jb20&color=%23039BE5&color=%230B8043"
                  style="border:solid 1px #777"
                  width="100%"
                  height="600"
                  frameborder="0"
                  scrolling="no"
                ></iframe>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-container>
    <OASideBySide
      :src="require('@/assets/masthead-den-front.jpg')"
      alignment="left"
    >
      <template v-slot:title>
        Booking Enquiry
      </template>
      <template v-slot:subtitle>
        <span v-if="!submitted">
          Please complete as much detail as possible. You'll be able to change
          the arrival and departure times later. Note, that during school term,
          check-in on Friday will typically be 7pm (after our last Scout
          meeting). Check-in and Check-out on Saturday, Sunday or outside of
          term will be by mutual agreement.
        </span>
        <span v-else>
          Thanks for your enquiry. We'll reply shortly.
        </span>
      </template>
      <template v-slot:text>
        <v-form v-if="!submitted" ref="form" v-model="valid">
          <v-row>
            <v-col cols="12" md="4">
              <v-text-field
                prepend-icon="mdi-account-group"
                label="Group Name"
                v-model="group"
                :rules="groupRules"
              />
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field
                prepend-icon="mdi-account"
                label="Contact Name"
                v-model="contact"
                :rules="contactRules"
              />
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field
                prepend-icon="email"
                label="Email"
                v-model="email"
                :rules="emailRules"
              />
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field
                prepend-icon="mdi-bunk-bed"
                label="Guests"
                v-model="guests"
                :rules="guestRules"
              />
            </v-col>
            <v-col cols="12" md="4">
              <v-select
                prepend-icon="house"
                v-model="facility"
                :items="facilities"
                label="Facilities"
              ></v-select>
            </v-col>
            <v-col cols="12" md="4">
              <v-menu
                ref="arrivalMenu"
                v-model="arrivalMenu"
                :close-on-content-click="false"
                :return-value.sync="eta"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="eta"
                    label="Arrival Date"
                    prepend-icon="event"
                    readonly
                    v-on="on"
                    :rules="etaRules"
                  ></v-text-field>
                </template>
                <v-date-picker v-model="eta" no-title scrollable>
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="arrivalMenu = false"
                    >Cancel</v-btn
                  >
                  <v-btn
                    text
                    color="primary"
                    @click="$refs.arrivalMenu.save(eta)"
                    >OK</v-btn
                  >
                </v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12" md="4">
              <v-menu
                ref="departureMenu"
                v-model="departureMenu"
                :close-on-content-click="false"
                :return-value.sync="etd"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="etd"
                    label="Departure Date"
                    prepend-icon="event"
                    readonly
                    v-on="on"
                    :rules="etdRules"
                  ></v-text-field>
                </template>
                <v-date-picker v-model="etd" no-title scrollable>
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="departureMenu = false"
                    >Cancel</v-btn
                  >
                  <v-btn
                    text
                    color="primary"
                    @click="$refs.departureMenu.save(etd)"
                    >OK</v-btn
                  >
                </v-date-picker>
              </v-menu>
            </v-col>

            <v-col cols="12" md="4">
              <v-text-field
                prepend-icon="phone"
                label="Phone (optional)"
                v-model="phone"
              />
            </v-col>

            <v-col cols="12" md="12">
              <v-checkbox
                v-model="agreement"
                label="I agree that Portmarnock Guide and Scout Den Trust CLG
              may store the details that I submit through this form."
                :rules="agreementRules"
              ></v-checkbox>
            </v-col>
          </v-row>
        </v-form>
      </template>
      <template v-slot:actions>
        <v-btn
          v-if="!submitted"
          :disabled="!valid"
          @click="submit"
          class="primary"
          >Submit</v-btn
        >
        <v-btn class="warning" @click="cancel">Reset</v-btn>
      </template>
    </OASideBySide>
  </section>
</template>

<script>
import OASideBySide from "@/components/OASideBySide.vue";
import fb from "@/fb";

export default {
  name: "Booking",
  components: {
    OASideBySide,
  },
  data: () => ({
    siteKey: "6Ld2FtIUAAAAANXyPU0lqjcAHNsQfdb36gKedpWV",
    group: null,
    contact: null,
    email: null,
    phone: null,
    guests: null,
    arrivalMenu: false,
    agreement: false,
    eta: new Date().toISOString().substr(0, 10),
    departureMenu: false,
    etd: new Date().toISOString().substr(0, 10),
    facility: null,
    valid: false,
    facilities: [
      "Whole Den",
      "Scout Hall",
      "Guide Hall",
      "Board Room/Meeting Room",
    ],
    submitted: false,
    groupRules: [(v) => !!v || "A name for your group is required."],
    contactRules: [(v) => !!v || "Your name is required."],
    emailRules: [
      (v) => !!v || "An email address for you is required.",
      (v) => /.+@.+\..{2,}/.test(v) || "E-mail must be valid",
    ],
    guestRules: [
      (v) => /[0-9]+/.test(v) || "You need to enter the number of guests.",
    ],
    agreementRules: [
      (v) => !!v || "You need to agree to our GDPR conditions to proceed.",
    ],
  }),
  mounted() {},
  computed: {
    etaRules() {
      return [
        (v) => !!v || "Your preferred arrival date is required.",
        (v) =>
          v <= this.etd ||
          "Your arrival date cannot be after your departure date.",
      ];
    },
    etdRules() {
      return [
        (v) => !!v || "Your preferred departure date is required.",
        (v) =>
          v >= this.eta ||
          "Your departure date cannot be before your arrival date.",
      ];
    },
  },
  methods: {
    submit() {
      this.submitted = true;
      const booking = {
        group: this.group,
        contact: this.contact,
        email: this.email,
        phone: this.phone || "Not Provided.",
        guests: this.guests,
        eta: this.eta,
        etd: this.etd,
        facility: this.facility,
        submitted: new Date().toString(),
        agreement: this.agreement,
      };
      fb.db.collection("portmarnockden").add(booking);
    },
    cancel() {
      this.resetForm();
    },
    resetForm() {
      this.submitted = false;
      this.$refs.form.reset();
      this.eta = new Date().toISOString().substr(0, 10);
      this.etd = new Date().toISOString().substr(0, 10);
    },
  },
};
</script>

<style></style>
