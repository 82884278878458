<template>
  <v-footer color="primary lighten-1" padless>
    <v-row justify="center" no-gutters>
      <v-col class="text-center" cols="12">
        <v-btn
          v-for="link in links"
          :key="`${link.label}-footer-link`"
          color="white"
          text
          rounded
          class="my-2"
          :to="link.url"
        >
          {{ link.label }}
        </v-btn>
      </v-col>

      <v-col class="primary lighten-2 py-4 text-center white--text" cols="12">
        Copyright &copy; {{ new Date().getFullYear() }}
        <strong>Portmarnock Guide and Scout Den Trust CLG. CHY 20430</strong>
        All rights reserved.
      </v-col>
    </v-row>
  </v-footer>
</template>

<script>
export default {
  name: "Footer",
  props: {
    links: {
      type: Array,
      default: () => [],
    },
  },
  computed: {},
};
</script>
