<template>
   <v-app>
    <link
      href="https://fonts.googleapis.com/css?family=Material+Icons"
      rel="stylesheet"
    >
    
    <Navbar :links="links" />
    <v-content>
      <v-fade-transition mode="out-in">
        <router-view />
      </v-fade-transition>
    </v-content>
    <Footer :links="links" />
  </v-app>
</template>

<script>
import Navbar from './components/Navbar.vue'
import Footer from './components/Footer.vue'

export default {
  name: 'app',
  components: {
    Navbar,
    Footer,
  },
  data() {
    return {
      links: [
        {
          label: 'Book Now',
          url: '/booking'
        },
        {
          label: 'Scouts',
          url: '/scouts',
        },
        {
          label: 'Guides',
          url: '/guides',
        }    
      ],
    };
  },
}
</script>

<style>

</style>
