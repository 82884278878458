import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';

var firebaseConfig = {
    apiKey: "AIzaSyDu-DA_u3e8LYfL_UeZAygpMwSlUX4rpo0",
    authDomain: "tokody-124de.firebaseapp.com",
    databaseURL: "https://tokody-124de.firebaseio.com",
    projectId: "tokody-124de",
    storageBucket: "tokody-124de.appspot.com",
    messagingSenderId: "969403228378",
    appId: "1:969403228378:web:7d4e71bced9af413e8c5e3",
    measurementId: "G-E8Y1EKFVE0"
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);
// firebase.analytics();
const db = firebase.firestore();
const auth = firebase.auth();
const firestore = firebase.firestore;

export default { db, auth, firestore };