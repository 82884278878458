<template>
  <section>
    <v-container fluid>
      <v-img
        max-height="400"
        :src="require('@/assets/masthead-den-front.jpg')"
      />
    </v-container>

    <section id="intro">
      <OASideBySide
        :src="require('@/assets/showcase-garden.jpg')"
        alignment="right"
      >
        <template v-slot:title>
          Our Den
        </template>
        <template v-slot:subtitle>
          We are incredibly fortunate to have a fantastic den in an ideal
          location. The den is available to book for over-night accommodation,
          one-off events, summer camps, or for clubs and activities. For example
          we have been pleased to host:
          <ul>
            <li>Portmarnock Athletics Club</li>
            <li>Irish Dancing classes</li>
            <li>Taekwondo classes</li>
            <li>A knitting group</li>
            <li>Bricks-4-kids camps</li>
            <li>Regional Scout and Girl Guide meetings</li>
            <li>
              Weekend camps for numerous Irish and international Scout and Guide
              troops
            </li>
          </ul>
        </template>
        <template v-slot:actions>
          <v-btn color="primary white--text" to="/booking">
            Book Now<v-icon>mdi-arrow-right-bold</v-icon>
          </v-btn>
        </template>
      </OASideBySide>
    </section>
    <section id="facilities">
      <OASideBySide
        :src="require('@/assets/showcase-garden.jpg')"
        alignment="left"
      >
        <template v-slot:title>
          Our Facilities
        </template>
        <template v-slot:subtitle>
          Our den is fully wheelchair accessible and has all relevant fire
          safety certificates. Many groups (including our own) have slept in the
          halls, and in those situations we generally use the concourse for the
          adult accommodation. The den consists of the following:
          <ul class="lead">
            <li>
              Scout Hall - approx 20m x 12m. Plenty of loose chairs and tables.
            </li>
            <li>
              Guide Hall - approx 18m x 10m. Plenty of benches and tables.
            </li>
            <li>
              Concourse - with a selection of couches and access to the garden.
            </li>
            <li>
              Kitchen - full commercial grade kitchen with seating for 8-10
              around the table.
            </li>
            <li>Boardroom - can seat about 12 people around the table.</li>
            <li>
              Bathrooms for girls, boys and a seperate bathroom for adults.
            </li>
            <li>Garden - with gas BBQ, seating, fire-pit and woodstack.</li>
          </ul>
        </template>
      </OASideBySide>
    </section>
    <section id="outside">
      <OASideBySide
        :src="require('@/assets/showcase-den-playground.jpg')"
        alignment="right"
      >
        <template v-slot:title>
          Outdoors
        </template>
        <template v-slot:subtitle>
          The area around the Den is ideal for a range of scouting and sporting
          activities.
          <ul class="lead">
            <li>
              Portmarnock Sports & Leisure Centre - just across the car park
              from us. It has a 25m pool, a sports hall, squash courts, tennis
              courts, astro-turf soccer pitches, a gym, and a bar and cafe.
            </li>
            <li>
              Blackwood Forest - just behind the Den a great place for wide
              games and scouting activities.
            </li>
            <li>
              Portmarnock Community Fields - right outside the front door. 10
              hectares of grassland partially laid out as sports pitches and
              open parkland.
            </li>
            <li>
              Playground - there is a small playground at the front of the Den.
            </li>
            <li>
              Beach - One of the nicest beaches in the country with a 4km strand
              with beautiful views out over Lambay, Ireland's Eye and Howth. It
              is only a 1.2km walk from the Den.
            </li>
          </ul>
        </template>
      </OASideBySide>
    </section>
    <section id="transport">
      <OASideBySide
        :src="require('@/assets/showcase-dublin-bus.jpg')"
        alignment="left"
      >
        <template v-slot:title>
          Transport
        </template>
        <template v-slot:subtitle>
          We are well served by public transport.
          <ul class="lead">
            <li>
              Bus - a short walk to stops for the H2, 42 and 102 bus routes.
            </li>
            <li>Malahide Train Station - a 2.8km walk.</li>
            <li>Portmarnock Train Station - a 3km walk.</li>
            <li>
              Sutton Train Station - can be reached using the 102 bus route in
              about 20 minutes.
            </li>
            <li>
              <a href="https://www.dublinairport.com/">Dublin Airport</a> - can
              be reached using the 102 bus route in about 30 minutes.
            </li>
          </ul>
        </template>
      </OASideBySide>
    </section>
    <section id="transport">
      <OASideBySide
        :src="require('@/assets/showcase-malahide-park.jpg')"
        alignment="right"
      >
        <template v-slot:title>
          Further afield
        </template>
        <template v-slot:subtitle>
          If you want to venture beyond Portmarnock, there is plenty to do that
          is easily reached using public transport.
          <ul class="lead">
            <li>
              <a href="https://www.malahidecastleandgardens.ie/"
                >Malahide Castle & Park</a
              >
              - a 2.2km walk from the Den, or take a bus.
            </li>
            <li>
              <a href="https://movies-at.ie/swords-cinema/">Cinema</a> - at the
              Pavillions Shopping Centre, Swords can be reached using the 102.
            </li>
            <li>
              <a href="https://leisureplex.ie/location/coolock/"
                >Cinema & Plex</a
              >
              - in Coolock can be reached on the 42.
            </li>
            <li>
              <a href="https://www.visitdublin.com/">Dublin City Centre</a> -
              can be reached by bus or train in about 50-60 minutes.
            </li>
            <li>
              <a href="http://www.visithowth.ie/">Howth</a> - can be reached
              using the 102 to Sutton train station followed by a 3km hike, or
              taking the train.
            </li>
            <li>
              <a href="https://www.modelrailwaymuseum.ie/model-railway-museum"
                >Fry's Model Railway Museum</a
              >
              - In Malahide village
            </li>
          </ul>
        </template>
      </OASideBySide>
    </section>
  </section>
</template>

<script>
import OASideBySide from "@/components/OASideBySide";

export default {
  name: "Home",
  components: {
    OASideBySide,
  },
  data: () => ({}),
  computed: {},
  created() {},
};
</script>
