<template>
  <v-container>
    <v-row>
      <v-col col="12">
        <v-banner class="orange lighten-2 white--text">
          <span class="display-1">
            <v-icon
              size="72px"
              color="white"
            >mdi-alert-decagram</v-icon>
            Ooopps! That page doesn't seem to exist.
          </span>
        </v-banner>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'PageNotFound',
};
</script>
