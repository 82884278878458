<template>
  <v-container
    class="white"
    fluid
  >
    <v-container
      class="white"
    >
      <v-row align="center">
        <v-col
          cols="12"
          md="6"
          :order-md="textorder"
          order-sm="2"
        >
          <v-card
            class="elevation-0"
            color="white"
          >
            <v-card-title
              class="display-1 blue-grey--text text--darken-2 font-weight-bold"
              style="word-break: keep-all;"
            >
              <slot name="title" />
            </v-card-title>
            <v-card-subtitle class="headline blue-grey--text text--darken-1">
              <slot name="subtitle" />
            </v-card-subtitle>
            <v-card-text><slot name="text"/></v-card-text>
            <v-card-actions>
              <slot name="actions" />
            </v-card-actions>
          </v-card>
        </v-col>
        <v-col
          cols="12"
          md="6"
          :order-md="imgorder"
          order-sm="1"
        >
          <v-card
            justify="center"
            align="center"
            class="elevation-0"
          >
            <v-img
              alt="Alternative text for screenreaders"
              :src="src"
            />
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-container>
</template>

<script>
export default {
    name: 'OASideBySide',
    props: {
        src: {
            type: String,
            required: true,
        },
        alignment: {
            type: String,
            required: false,
            default: () => 'left'
        }
    },
    computed: {
        textorder() {
            return this.alignment === 'left' ? 1 : 2;
        },
        imgorder() {
            return this.textorder === 1 ? 2 : 1;
        }
    }
}
</script>

<style>

</style>