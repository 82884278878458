import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '@/views/Home.vue';
import Booking from '@/views/Booking.vue';
import PageNotFound from '@/views/errors/PageNotFound.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home,
  },
  {
    path: '/booking',
    name: 'booking',
    component: Booking,
  },
  {
    path: '/scouts',
    beforeEnter() { location.href = "https://portmarnockscouts.com" }
  },
  {
    path: '/guides',
    beforeEnter() { location.href = "https://portmarnockguides.com" }
  },
  {
    path: '*',
    name: 'PageNotFound',
    component: PageNotFound
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
