<template>
  <v-app-bar
    fixed
    app
    
    
    elevate-on-scroll
    height="80"
  >
    <v-img
      :src="require('@/assets/120th-logo.png')"
      max-width="90px"
      class="mr-2"
    />
    <v-img
      :src="require('@/assets/igg-logo.png')"
      max-width="80px"
      class="mr-2"
    />
    <v-toolbar-title>
      <router-link
        to="/"
        class="site-title"
      >
        <span class="grey--text text--darken-2">Portmarnock Scout & Guide Den</span>
      </router-link>
    </v-toolbar-title>
    
    <v-spacer />
    <span class="hidden-sm-and-down">
     
      <div>
        <v-btn
          v-for="link in links"
          :key="`${link.label}-header-link`"
          text
          rounded
          :to="link.url"
        >
          {{ link.label }}
        </v-btn>
        
      </div>
    </span>
    <span class="hidden-md-and-up">
      <v-menu
        offset-y
        open-on-hover
      >
        <template v-slot:activator="{ on }">
          <v-app-bar-nav-icon v-on="on" />
        </template>
        <v-list>
          <v-list-item
            v-for="link in links"
            :key="link.url"
            :to="link.url"
          >
            <v-list-item-content>
              <v-list-item-title>{{ link.label }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-menu>
    </span>
  </v-app-bar>
</template>

<script>
export default {
  name: 'Navbar',
  props: {
    links: { 
      type: Array,
      default: () => [],
    },
  },
  computed: {
    
  },
  methods: {
    
  },
};
</script>

<style scoped>
.site-title {
  font-family: 'Ubuntu', sans-serif;
  
  text-decoration: none;
  font-size: 120%;
  font-weight: 700;
}
</style>
